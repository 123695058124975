<template>
  <div :class="navigator_logo_class">
    <div v-if="logoIf">
      <el-image
      class="logo-image"
      v-if="!start_to_expand"
      fit="contain"
      :src="imageSrc?imageSrc:imageSrc1"
    />
    </div>
    
    <!-- :src="imageSrc?imageSrc:imageSrc1" -->
    <!-- <span v-if="!is_collapse && !start_to_expand">{{app.name}}</span> -->
    <span v-if="!is_collapse && !start_to_expand && logoIf">{{logoName}}</span>
    <el-popover
        placement="bottom"
        title="版本更新"
        :width="300"
        trigger="hover"
        content="this is content, this is content, this is content"
    >
      <template #reference>
        <div class="version-text">{{updateData.versionNo}}</div>
      </template>
      <p>{{updateData.versionInformation}}</p>
      <div>
        <p><b>前台代码版本 :</b> {{updateData.frontCodeVersion}}</p>
        <p><b>后台代码版本 :</b> {{updateData.backCodeVersion}}</p>
      </div>
      <div>
        <el-tag type="success">新增：</el-tag>
        <div v-html="updateData.addInformation">
        </div>
      </div>
      <div>
        <el-tag type="warning">修复：</el-tag>
        <div v-html="updateData.repairInformation">
        </div>
      </div>
      <div>
        <el-tag type="info">优化：</el-tag>
        <div v-html="updateData.optimizeInformation">
        </div>
      </div>
    </el-popover>
  </div>
</template>
<script setup>
import { computed, inject, onMounted, ref, watch } from "vue";
import { useStore } from 'vuex';
import { apiGetNewVersion,getLogo ,api_get_file } from '@/api/auth.api';

import { ElMessage } from "element-plus";

//平台名称
const logoName = ref("运营平台")
const imageSrc1 = null // 默认图片
let imageSrc = ref(null);
let logoIf = ref(false);
// let imageSrc = 'blob:http://localhost:8888/91e55ae9-f62a-4022-a608-34a83c70a951'
const app = inject('app_config');
const store = useStore();

const start_to_expand = ref(false);
const is_collapse = computed(() => store.state.navigator.is_collapse);
const updateData = ref({ versionNo: null, versionInformation: null, frontCodeVersion: null, backCodeVersion: null, addInformation: null, repairInformation: null, optimizeInformation: null });
const navigator_logo_class = computed(() => {
  return {
    'navigator-logo': true,
    'is-collapsed': is_collapse.value
  };
});
//获取logo
const getLogoA = async () => {
  try {
    const { data } = await getLogo();
    if (data) {
      if (data === null || data.logoMongoId === null){
        logoIf.value = true
        imageSrc1.value = require('@/assets/logo.png')
        return;
      }
      getLogoFile(data.logoMongoId)
      if (data.logoName !== null){
        logoName.value = data.logoName
      }
      
    } else {
      imageSrc.value = require('@/assets/logo.png')
    }
  } catch (error) {
    imageSrc.value = require('@/assets/logo.png')
  }

};

const getLogoFile=async(logoMongoId)=>{
  const result = await api_get_file(logoMongoId);
  logoIf.value = true
  imageSrc.value = window.URL.createObjectURL(result)
}


// 使文字在展开动画完成后再显示
// 避免展开动过过程中出现文字因width压缩而换行
watch(
  () => is_collapse.value,
  () => {
    if (!is_collapse.value) {
      start_to_expand.value = true;
      setTimeout(() => {
        start_to_expand.value = false;
      }, 300);
    }
  }
);
/**
 * 获取版本数据
 * @returns {Promise<void>}
 */
const getVetsionData = async () => {
  try {
    const { data } = await apiGetNewVersion();
    if (data) {
      updateData.value = data;
      updateData.value.addInformation = updateData.value.addInformation ? updateData.value.addInformation.replace(/\n/g, '<br>') : null;
      updateData.value.repairInformation = updateData.value.repairInformation ? updateData.value.repairInformation.replace(/\n/g, '<br>') : null;
      updateData.value.optimizeInformation = updateData.value.optimizeInformation ? updateData.value.optimizeInformation.replace(/\n/g, '<br>') : null;
    } else {
      ElMessage.warning('无版本信息数据');
    }
  } catch (error) {
    ElMessage.error('获取版本信息失败');
  }
};
onMounted(() => {
  getLogoA();
  getVetsionData();
});
</script>
<style lang="scss" scoped>
.navigator-logo {
  height: var(--charging-pile-navigator-logo-height);
  width: var(--charging-pile-size-navigator-width);
  padding: 1.2rem;
  // border-bottom: var(--charging-pile-split-border);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 1.6rem;
  color: white;
  // justify-content: center;
  .logo-image {
    //height: 6rem;
    //width: 12rem;
    height: 7.5rem;
    width: 15rem;
    // margin-right: 1.2rem;
    margin-bottom: 1.2rem;
  }
  &.is-collapsed {
    width: var(--charging-pile-size-navigator-collapsed-width);
    justify-content: center;
    .logo-image {
      margin-right: 0;
    }
  }

  .version-text {
    margin-top: 1.2rem;
    font-size: 1.2rem;
  }
}
</style>
