import { createRouter, createWebHistory } from 'vue-router';
import main from '@/views/main';
import cabin from '@/views/cabin';
import app_config from '@/config/app.config.js';
import permission_deny from '@/views/main/permission-deny';
import { navigator_types } from '@/store/navigator.store';
import { get_perms_gui } from '@/api/auth.api.js';

const module_routes = app_config.modules.filter(m => !m.is_not_menu_path).map(module => {
  const route = {
    path: module.path,
    component: module.component,
    meta: { navigator_index: `${module.path}` }
  };
  if (module.children) {
    route.children = [];
    module.children.forEach(sub_module => {
      route.children.push({
        path: sub_module.path,
        component: sub_module.component,
        meta: { navigator_index: `${module.path}/${sub_module.path}` }
      });
      (sub_module.extra_routes || []).forEach(extra_route => {
        route.children.push({
          path: extra_route.path,
          component: extra_route.component,
          meta: { navigator_index: `${module.path}/${sub_module.path}` }
        });
      });
    });
  }
  return route;
});
console.log('module_routes',module_routes)
const routes = [
  {
    path: '/cabin',
    component: cabin
  },
  {
    path: '/',
    component: main,
    children: [
      {
        path: '/index',
        link:'/index',
        component: ()=>import('@/views/main/modules/index/index.vue')
      },
      ...module_routes,
      {
        path: 'permission-deny',
        component: permission_deny
      }
    ]
  },
  {
    path: '/map',
    component: () => import('@/views/map.vue')
  },
  {
    path: '/login',
    component: () => import('@/views/login')
  },
  {
    path: '/canvas',
    component: () => import('@/views/main/modules/canvas/index')
  }
];

console.log('routes:', routes);

const router = createRouter({
  history: createWebHistory(),
  routes
});

const safe_paths = ['/login'];

export const set_router_guard = (_router, store) => {
  _router.beforeEach(async (to, from, next) => {
    if (safe_paths.includes(to.path)) {
      next();
      return;
    }
    // 检测是否登录
    if (!store.state.auth.access_token) {
      const storage_token = window.localStorage.getItem('access_token');
      if (!storage_token) {
        const query = {};
        if (to.fullPath !== '/') {
          query.backUrl = to.fullPath;
        }
        next({ path: '/login', query });
        return;
      } else {
        store.state.auth.access_token = storage_token;
      }
    }
    // 获取permissions
    // console.log('获取permissions',store.state.navigator.permissions)
    if (store.state.navigator.permissions === null) {
      const { data } = await get_perms_gui();
      store.commit(navigator_types.m_update_permissions.path, data);
    }
    if(to.matched&&to.matched.length>2){
      to.matched.splice(1,to.matched.length-2)
    }
    next();
  });
};

export default router;
