import call, {down} from './call';
import axios from "axios";
export const get_oss_key = async () => {
  return call.get('/mgmt/sc-file/upload/token');
};

export const download = async (key, filename) => {
  const data = await call.get(`/mgmt/sc-file/download/${key}`);
  const blob = new Blob([data], { type: 'application/octet-stream' });
  // 不兼容IE
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', decodeURI(filename));
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
};

const data_url_2_blob = (data_url) => {
  const arr = data_url.split(','); 
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = window.atob(arr[1]); 
  let n = bstr.length; 
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};
export const download_base64 = (base64, filename) => {
  const blob = data_url_2_blob(base64);
  const blobURL = URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', decodeURI(filename));
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  window.URL.revokeObjectURL(blobURL);
};

export const upload_file = (authorization, file, fileKey) => {
  const data = new FormData();
  data.append('policy', `${authorization.policy}`);
  data.append('signature', authorization.signature);
  data.append('OSSAccessKeyId', authorization.OSSAccessKeyId);
  data.append('success_action_status', 201);
  data.append('key', fileKey);
  data.append('file', file);
  return call.post(
    `https:${authorization.host}`,
    data,
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      file_upload: true,
      full_path: true,
      no_auth: true
    }
  );
};

export const api_upload_file=(file)=>{
  const data = new FormData();
  data.append('file', file);
  return call.post(`/statistics/Ota/uploadOtaNew`,data,{
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
/**
 * 上传多个文件
 * @param filse
 * @returns {Promise<AxiosResponse<any>>}
 */
export const api_upload_file_list=(files)=>{
  return call.post(`/statistics/Ota/uploadOtaList`,files,{
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}
export const api_get_file=(id)=>{
  return axios.get(`/api/v1/statistics/Ota/getByIdFile?id=${id}`,{
    responseType:'blob',
    headers:{
      Authorization: window.localStorage.getItem('access_token')
    },
  })
}

// 添加logo
export const addLogo = (params)=> {
  return call.post(`/statistics/logo/insert`,params)
}
// 获取上传的logo列表
export const getLogoList = ()=> {
  return call.get(`/statistics/logo/getList`)
}
//删除logo图片
export const deleteLogoImg = (id)=> {
  return call.get(`/statistics/logo/deleteById?id=${id}`)
}
// 获取场站列表
export const getStationListByLogoId = (logoId)=> {
  return call.get(`/statistics/logo-configuration/getLogoIdList?logoId=${logoId}`)
}
// 修改logo提交
export const modifyLogoSubmit = (params)=>{
  return call.post(`/statistics/logo/updateByIdLogo`,params)
}